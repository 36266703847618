import { Contract, Studio } from '@definitions/types/symfonyTypesd'

import { StudioFinder } from './StudioFinder'

export const dummyStudios: Studio[] | any[] = [
  {
    __typename: 'Studio',
    address: '123 Main St',
    adyenMerchantAccount: 'merchant_123',
    affiliations: null,
    categories: null,
    cidNumber: 'cid_123',
    clientStudioId: 'client_123',
    contracts: null,
    coupons: null,
    hide: false,
    id: 'ID_123',
    additionalInfo: 'Dummy additional information',
    info: 'This is dummy studio 1.',
    latitude: 40.7128,
    longitude: -74.006,
    name: 'Dummy Studio 1',
    openingHoursWeekdays: '10-16',
    openingHoursLastEntrance: '9-15',
    openingHoursPublicHoliday: '11-17',
    openingHoursSaturday: '10-14',
    openingHoursSunday: '12-18',
    operator: null,
    parkingAvailable: false,
    payPalMerchantId: 'paypal_123',
    phone: '+123456789',
    seoDescription: 'SEO description 1',
    seoKeywords: 'keywords, seo',
    seoTitle: 'SEO Title 1',
    sisyCashbox: false,
    slug: 'dummy-studio-1',
    specialOpeningHour: '9-17',
    specialOpeningHourInfo: 'Open on weekends',
    studioOptions: null,
    studioType: 'Type 1',
    title: 'Studio 1',
  },
  {
    __typename: 'Studio',
    address: '456 Market St',
    adyenMerchantAccount: 'merchant_456',
    affiliations: null,
    categories: null,
    cidNumber: 'cid_456',
    clientStudioId: 'client_456',
    contracts: null,
    coupons: null,
    hide: false,
    id: 'ID_456',
    additionalInfo: 'Dummy additional information',
    info: 'This is dummy studio 2.',
    latitude: 40.7234,
    longitude: -74.015,
    name: 'Dummy Studio 2',
    openingHoursWeekdays: '9-18',
    openingHoursLastEntrance: '8-16',
    openingHoursPublicHoliday: '10-17',
    openingHoursSaturday: '9-15',
    openingHoursSunday: '11-19',
    operator: null,
    parkingAvailable: true,
    payPalMerchantId: 'paypal_456',
    phone: '+234567890',
    seoDescription: 'SEO description 2',
    seoKeywords: 'keywords, seo 2',
    seoTitle: 'SEO Title 2',
    sisyCashbox: true,
    slug: 'dummy-studio-2',
    specialOpeningHour: '10-16',
    specialOpeningHourInfo: 'Closed on weekends',
    studioOptions: null,
    studioType: 'Type 2',
    title: 'Studio 2',
  },
  {
    __typename: 'Studio',
    address: '789 Broadway St',
    adyenMerchantAccount: 'merchant_789',
    affiliations: null,
    categories: null,
    cidNumber: 'cid_789',
    clientStudioId: 'client_789',
    contracts: null,
    coupons: null,
    hide: true,
    id: 'ID_789',
    additionalInfo: 'Dummy additional information',
    info: 'This is dummy studio 3.',
    latitude: 40.7345,
    longitude: -74.0235,
    name: 'Dummy Studio 3',
    openingHoursWeekdays: '11-19',
    openingHoursLastEntrance: '10-18',
    openingHoursPublicHoliday: '12-20',
    openingHoursSaturday: '11-17',
    openingHoursSunday: '13-21',
    operator: null,
    parkingAvailable: false,
    payPalMerchantId: 'paypal_789',
    phone: '+345678901',
    seoDescription: 'SEO description 3',
    seoKeywords: 'keywords, seo 3',
    seoTitle: 'SEO Title 3',
    sisyCashbox: false,
    slug: 'dummy-studio-3',
    specialOpeningHour: '9-17',
    specialOpeningHourInfo: 'Open every day',
    studioOptions: null,
    studioType: 'Type 3',
    title: 'Studio 3',
  },
]

export const dummyContracts: Contract[] = [
  {
    __typename: 'Contract',
    conditions: 'Sample conditions 1',
    contractId: 'C12345',
    contractValue: 1234.56,
    duration: '12 months',
    durationDescription: '12 months from start date 1',
    hide: false,
    id: 'ID123456',
    infoInLayer: 'Sample info in layer 1',
    monthlyFee: 100.0,
    monthlyFeeDescription: 'Monthly fee description 1',
    priceCalculationMembership: 'Sample price calculation 1',
    promotionText: 'Sample promotion text 1',
    standard: true,
    title: 'Sample Contract Title 1',
    weeklyFee: 25.0,
    weeklyFeeDescription: 'Weekly fee description 1',
    affiliations: null,
    costs: null,
    replacedByContract: null,
    replacedContract: null,
    studios: null,
    validFrom: null,
    vouchers: null,
    contractType: 'Type 1',
    insteadPrice: 13,
    specialOffer: 'false',
    weeklyInterval: '1 week',
    highlighted: false,
    priority: 0,
  },
  {
    __typename: 'Contract',
    conditions: 'Sample conditions 2',
    contractId: 'C12346',
    contractValue: 1345.67,
    duration: '24 months',
    durationDescription: '24 months from start date 2',
    hide: true,
    id: 'ID123457',
    infoInLayer: 'Sample info in layer 2',
    monthlyFee: 110.0,
    monthlyFeeDescription: 'Monthly fee description 2',
    priceCalculationMembership: 'Sample price calculation 2',
    promotionText: 'Sample promotion text 2',
    standard: false,
    title: 'Sample Contract Title 2',
    weeklyFee: 27.5,
    weeklyFeeDescription: 'Weekly fee description 2',
    affiliations: null,
    costs: null,
    replacedByContract: null,
    replacedContract: null,
    studios: null,
    validFrom: null,
    vouchers: null,
    contractType: 'Type 2',
    insteadPrice: 1,
    specialOffer: 'false',
    weeklyInterval: '2 weeks',
    highlighted: false,
    priority: 0,
  },
  {
    __typename: 'Contract',
    conditions: 'Sample conditions 3',
    contractId: 'C12347',
    contractValue: 1456.78,
    duration: '6 months',
    durationDescription: '6 months from start date 3',
    hide: false,
    id: 'ID123458',
    infoInLayer: 'Sample info in layer 3',
    monthlyFee: 120.0,
    monthlyFeeDescription: 'Monthly fee description 3',
    priceCalculationMembership: 'Sample price calculation 3',
    promotionText: 'Sample promotion text 3',
    standard: true,
    title: 'Sample Contract Title 3',
    weeklyFee: 30.0,
    weeklyFeeDescription: 'Weekly fee description 3',
    affiliations: null,
    costs: null,
    replacedByContract: null,
    replacedContract: null,
    studios: null,
    validFrom: null,
    vouchers: null,
    contractType: 'Type 3',
    insteadPrice: 7,
    specialOffer: 'false',
    weeklyInterval: '1 week',
    highlighted: false,
    priority: 0,
  },
]

export default StudioFinder
