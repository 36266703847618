import parse from 'html-react-parser'
import React, { PropsWithChildren, useState } from 'react'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import IconCircleCheck from '@components/atoms/Icons/IconCircleCheck'
import UnorderedList from '@components/atoms/UnorderedList'
import { StyledIconAccordionArrow } from '@components/molecules/Accordion/Accordion.styles'

import { formatEuro } from '@helper/currency'
import { parseOptions } from '@helper/parseOptions'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Contract as ContractType } from '@definitions/types/symfonyTypesd'

import {
  BoxBody,
  BoxHead,
  BoxHeadCost,
  BoxHeadCurrentCost,
  BoxHeadDuration,
  BoxHeadInsteadCost,
  BoxHeadInsteadPrice,
  BoxHeadInterval,
  BoxHeadPrice,
  BoxHeadTitle,
  ButtonWrapper,
  Content,
  ExpandButton,
  Root,
  Stoerer,
  StyledBox,
  StyledButton,
  StyledListItem,
} from './Contract.styles'

export interface Affiliation {
  id: number
}

export interface Cost {
  id: number
}
export interface ContractCols {
  xs: number
  sm: number
  md: number
  lg: number
}

interface Props extends PropsWithClassName {
  contract: ContractType
  handleSelectContract?: (contract: ContractType) => void
  button?: boolean
  cols: ContractCols
  breakpoint: number
}

const Contract: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { contract, handleSelectContract, button, cols } = props

  const handleClick = (contract: ContractType) => {
    if (handleSelectContract !== undefined) {
      handleSelectContract(contract)
    }
  }

  const [isExpanded, setIsExpanded] = useState(false)

  const savings: number =
    100 - Math.round((contract.weeklyFee / contract.insteadPrice) * 100)
  return (
    <Root className={props.className} data-testid={'contract-root'}>
      <StyledBox
        $isOffer={contract.highlighted}
        $isExpanded={isExpanded}
        $breakpoint={props.breakpoint}
      >
        {(contract.badgeLargeText?.length ||
          contract.badgeSmallText?.length) && (
          <Stoerer>
            {contract.badgeLargeText?.length && (
              <b>{contract.badgeLargeText}</b>
            )}
            {contract.badgeSmallText?.length && (
              <span>{contract.badgeSmallText}</span>
            )}
          </Stoerer>
        )}
        <Content>
          <BoxHead $breakpoint={props.breakpoint}>
            <BoxHeadDuration>{contract.duration}</BoxHeadDuration>
            <BoxHeadTitle>
              {contract.title.replace(contract.duration, '')}
            </BoxHeadTitle>
            <BoxHeadCost>
              <BoxHeadCurrentCost>
                <BoxHeadPrice>{formatEuro(contract.weeklyFee)}</BoxHeadPrice>
                <BoxHeadInterval>/Woche</BoxHeadInterval>
              </BoxHeadCurrentCost>
              {contract.insteadPrice > 0 && (
                <BoxHeadInsteadCost>
                  <span>statt </span>
                  <BoxHeadInsteadPrice>
                    {formatEuro(contract.insteadPrice)}
                  </BoxHeadInsteadPrice>
                </BoxHeadInsteadCost>
              )}
            </BoxHeadCost>
          </BoxHead>
          <BoxBody
            button={button ? button : false}
            className={isExpanded ? 'expanded' : 'collapsed'}
          >
            {contract.conditions && (
              <>{parse(contract.conditions, parseOptions)}</>
            )}
            <UnorderedList>
              <StyledListItem icon={<IconCircleCheck active />}>
                {`${contract.duration}`}
              </StyledListItem>
              {contract.weeklyFeeDescription && (
                <StyledListItem icon={<IconCircleCheck active />}>
                  {`${contract.weeklyFeeDescription}`}
                </StyledListItem>
              )}
            </UnorderedList>
          </BoxBody>
        </Content>
        {button && (
          <ButtonWrapper>
            <ExpandButton
              buttonType={ButtonTypes.BUTTON}
              modifier={ButtonModifier.MINIMAL}
              disabled={false}
              loading={false}
              icon={<StyledIconAccordionArrow visible={isExpanded} />}
              iconAlign={IconAligns.RIGHT}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded
                ? 'Club-Vorteile einklappen'
                : 'Club-Vorteile ansehen'}
            </ExpandButton>
            <StyledButton
              disabled={false}
              buttonType={ButtonTypes.BUTTON}
              modifier={
                contract.highlighted
                  ? ButtonModifier.SECONDARY
                  : ButtonModifier.TERTIARY
              }
              iconAlign={IconAligns.RIGHT}
              icon={<IconArrowRight />}
              loading={false}
              onClick={() => handleClick(contract)}
            >
              auswählen & sparen
            </StyledButton>
          </ButtonWrapper>
        )}
      </StyledBox>
    </Root>
  )
}

export { Contract }
