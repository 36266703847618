import styled, { css } from 'styled-components'

import Box from '@components/atoms/Box'
import { Children } from '@components/atoms/Box/Box.styles'
import Button from '@components/atoms/Button'
import { Span } from '@components/atoms/Button/Button.styles'
import Headline from '@components/atoms/Headline'
import { Ellipse } from '@components/atoms/Icons/IconCircleCheck/IconCircleCheck.styles'
import ListItem from '@components/atoms/ListItem'
import {
  Icon,
  Root as ListItemRoot,
} from '@components/atoms/ListItem/ListItem.styles'

import { shouldUseWhiteText } from '@helper/color'

export const Root = styled.div`
  max-width: 35rem;
  width: 100%;
  padding-inline: 0.5rem;
  ul {
    margin: 0;
  }
  li {
    padding: 1.25rem 6px 1rem 2.2rem;
    border-bottom: 1px solid ${(props) => props.theme.palette.default.grey};
    ${Icon} {
      top: 1.375rem;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxHeadDuration = styled.div`
  font-weight: 700;
  font-size: 2rem;
`

export const BoxHeadTitle = styled.div`
  font-weight: 300;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxHeadCost = styled.span`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  gap: 0.125rem;
  align-items: center;
  justify-content: start;
`

export const BoxHeadCurrentCost = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const BoxHeadPrice = styled.span`
  font-weight: 500;
  font-size: 2.5rem;
`

export const BoxHeadInterval = styled.span`
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
  margin-left: 0.125rem;
`

export const BoxHeadInsteadCost = styled.div`
  font-size: 1.25rem;
  font-weight: 300;
`

export const BoxHeadInsteadPrice = styled.span`
  text-decoration: line-through;
  text-decoration-color: #0004;
`

export const BoxHead = styled.div<{ $breakpoint: number }>`
  padding: 3rem 1.9375rem 1.375rem 1.9375rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.default.grey};

  @media (min-width: ${(props) => props.$breakpoint}px) {
    ${BoxHeadTitle} {
      min-height: 3.1rem;
    }
    ${BoxHeadCost} {
      min-height: 4rem;
    }
  }
`

interface BoxBodyProps {
  button: boolean
}

export const BoxBody = styled.div<BoxBodyProps>`
  padding: 0.25rem 1.9375rem ${(props) => (props.button ? '0' : '2rem')}
    1.9375rem;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const BoxBodyHeadline = styled(Headline)`
  margin: 0;
`

export const Stoerer = styled.div`
  background: ${(props) => props.theme.palette.default.tertiary};
  color: ${(props) =>
    shouldUseWhiteText(props.theme.palette.default.tertiary)
      ? 'white'
      : 'black'};
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -2.5625rem;
  right: 10%;
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 3.125rem;
  margin: auto;
  text-align: center;
  font-style: italic;
  b {
    font-size: 2rem;
    font-weight: 500;
  }
`

export const StyledListItem = styled(ListItem)`
  padding: 16px 6px 16px 2.2rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.default.grey};
  ${Icon} {
    top: 18px;
  }
  &:last-child {
    border-bottom: none;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 2rem 2rem;
  gap: 0.5rem;
`

export const ExpandButton = styled(Button)`
  display: none;
  font-weight: 500;
  font-size: 1.125rem;
  --icon-size: 1.75rem;
  margin: 0.25rem 0 0 0;
  ${Span} {
    color: ${(props) => props.theme.palette.default.tertiary};
  }
  svg path {
    fill: ${(props) => props.theme.palette.default.tertiary};
  }
`

export const StyledButton = styled(Button)`
  margin: 0;
  display: flex;
  flex-grow: 1;
  font-weight: 500;
  border: none;
  :hover {
    border: none;
  }
  svg {
    margin-top: -0.1rem;
  }
`

export const StyledBox = styled(Box)<{
  $isOffer?: boolean
  $isExpanded?: boolean
  $breakpoint?: number
}>`
  padding: 0 !important;
  margin-bottom: 0;
  flex-direction: column;
  height: 100%;

  ${Children} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  ${(props) => css`
    @media (max-width: ${props.$breakpoint}px) {
      ${ExpandButton} {
        display: flex;
      }
      ${BoxBody}.collapsed {
        display: none;
      }
    }
  `};

  ${(props) => {
    if (props.$isOffer) {
      return css`
        background-color: ${props.theme.key === 'wellmaxx'
          ? props.theme.palette.default.primary
          : props.theme.palette.button.secondary.background};
        color: ${props.theme.key === 'wellmaxx'
          ? props.theme.palette.default.white
          : props.theme.palette.default.black};
        ${BoxHead} {
          border-color: ${props.theme.palette.default.greyLight};
        }
        ${ListItemRoot} {
          border-color: ${props.theme.palette.default.greyLight};
        }
        ${StyledButton} {
          :hover {
            background-color: ${props.theme.palette.button.primary.background};
            color: ${props.theme.palette.button.primary.text};
            svg path {
              fill: ${props.theme.palette.button.primary.text};
            }
          }
        }
        ${Ellipse} {
          fill: ${props.theme.key === 'wellmaxx'
            ? props.theme.palette.default.black
            : props.theme.palette.default.white};
        }
      `
    }
  }}
`
