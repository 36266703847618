import { useRouter } from 'next/router'
import React, { PropsWithChildren, useMemo, useRef } from 'react'
import { useTheme } from 'styled-components'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperRef } from 'swiper/react'

import {
  ButtonModifier,
  ButtonTypes,
  IconAligns,
} from '@components/atoms/Button/Button'
import Col from '@components/atoms/Grid/Col'
import Row from '@components/atoms/Grid/Row'
import IconArrowLeft from '@components/atoms/Icons/IconArrowLeft'
import IconArrowRight from '@components/atoms/Icons/IconArrowRight'
import Message from '@components/atoms/Message'
import { MessageTypes } from '@components/atoms/Message/Message'
import {
  Next,
  Prev,
} from '@components/molecules/ImageSlider/ImageSlider.styles'

import { PropsWithClassName } from '@helper/PropsWithClassName'

import {
  Contract as ContractType,
  Studio,
} from '@definitions/types/symfonyTypesd'

import { Contract } from '../Contract/Contract'
import {
  ContractViewSwitcher,
  Root,
  StyledButton,
  StyledContractContainer,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperArrows,
} from './Contracts.styles'

export interface Affiliation {
  id: number
}

export interface Cost {
  id: number
}

interface Props extends PropsWithClassName {
  contracts: ContractType[]
  handleSelectContract?: (contract: ContractType) => void
  studio: Studio | undefined
  showStudioButton: boolean
}

const Contracts: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { contracts, handleSelectContract, studio, showStudioButton } = props
  const theme: any = useTheme()
  const router = useRouter()

  const swiperRef = useRef<SwiperRef | null>(null)
  const breakpoint = 1100

  const contractsFiltered = useMemo(
    () =>
      contracts
        .filter((contract) => {
          return (
            contract.standard &&
            (contract.affiliations?.edges ?? []).filter(
              (e) => e?.node?.name.toLowerCase() === theme.key
            ).length > 0
          )
        })
        .sort((a, b) => b.priority - a.priority),
    [contracts, theme.key]
  )

  const handleStudioClick = (studio: Studio) => {
    let theme: string | null = null
    if (router.pathname.startsWith('/sunpoint')) {
      theme = 'sunpoint'
    } else if (router.pathname.startsWith('/wellmaxx')) {
      theme = 'wellmaxx'
    }
    if (theme === undefined) {
      theme = 'sunpoint'
    }
    router.push(`/${theme}/studio/${studio.slug}`)
  }

  const handleClickPrev = (): void => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) {
      return
    }
    if (swiper.realIndex - 1 >= 0) {
      swiper.slideToLoop(swiper.realIndex - 1)
    } else {
      swiper.slideToLoop(swiper.slides.length - 1)
    }
  }

  const handleClickNext = (): void => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) {
      return
    }
    if (swiper.realIndex + 1 === swiper.slides.length) {
      swiper.slideToLoop(0)
    } else {
      swiper.slideToLoop(swiper.realIndex + 1)
    }
  }

  return (
    <Root className={props.className} data-testid={'contracts-root'}>
      {contractsFiltered.length > 0 ? (
        <ContractViewSwitcher $breakpoint={breakpoint}>
          <StyledContractContainer>
            {contractsFiltered.map((contract, index) => {
              return (
                <Contract
                  key={contract.id + index}
                  cols={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                  handleSelectContract={handleSelectContract}
                  contract={contract}
                  button
                  breakpoint={breakpoint}
                />
              )
            })}
          </StyledContractContainer>
          <StyledSwiper
            slidesPerView={3.5}
            enabled={true}
            ref={swiperRef}
            allowSlideNext={contractsFiltered.length > 3}
            allowSlidePrev={contractsFiltered.length > 3}
            direction={'horizontal'}
            centeredSlides={true}
            initialSlide={contractsFiltered.length > 1 ? 1 : 0}
            pagination={
              contractsFiltered.length > 3 ? { clickable: true } : undefined
            }
          >
            {contractsFiltered.map((contract, index) => {
              return (
                <StyledSwiperSlide key={contract.id + index} $index={index}>
                  <Contract
                    cols={{ xs: 12, sm: 12, md: 6, lg: 6 }}
                    handleSelectContract={handleSelectContract}
                    contract={contract}
                    button
                    breakpoint={breakpoint}
                  />
                </StyledSwiperSlide>
              )
            })}
            {contractsFiltered.length > 3 && (
              <SwiperArrows>
                <Prev onClick={() => handleClickPrev()}>
                  <IconArrowLeft />
                </Prev>
                <Next onClick={() => handleClickNext()}>
                  <IconArrowRight />
                </Next>
              </SwiperArrows>
            )}
          </StyledSwiper>
        </ContractViewSwitcher>
      ) : (
        <Row>
          <Col xs={12} smOffset={1} sm={10}>
            <Message
              type={MessageTypes.INFO}
              headline="Angebote im Studio vor Ort"
            >
              <p>
                Für dieses Studio kann eine Mitgliedschaft nicht online
                geschlossen werden. Jetzt attraktive Angebote im Studio vor Ort
                abschließen!
              </p>
              <div>
                {showStudioButton && studio !== undefined && (
                  <StyledButton
                    disabled={false}
                    buttonType={ButtonTypes.BUTTON}
                    modifier={ButtonModifier.PRIMARY}
                    iconAlign={IconAligns.RIGHT}
                    icon={<IconArrowRight />}
                    loading={false}
                    onClick={() => handleStudioClick(studio)}
                  >
                    Informationen zum Studio
                  </StyledButton>
                )}
              </div>
            </Message>
          </Col>
        </Row>
      )}
    </Root>
  )
}

export { Contracts }
