import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

import Button from '@components/atoms/Button'
import {
  Next,
  Prev,
} from '@components/molecules/ImageSlider/ImageSlider.styles'

export const Root = styled.div`
  align-items: stretch;
`

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 0;
`

export const StyledContractContainer = styled.div`
  background-color: ${(props) => props.theme.palette.default.greyLight};
  display: flex;
  flex-direction: column;
  padding-block: 1rem;
  gap: 1.25rem;
  align-items: center;
`

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  padding-top: 3.25rem;
  padding-bottom: 2rem;
  --swiper-pagination-bottom: 0.5rem;
  background-color: ${(props) => props.theme.palette.default.greyLight};
`

export const ContractViewSwitcher = styled.div<{ $breakpoint: number }>`
  ${(props) => {
    return css`
      ${StyledContractContainer} {
        @media (min-width: ${props.$breakpoint + 1}px) {
          display: none;
        }
      }
      ${StyledSwiper} {
        @media (max-width: ${props.$breakpoint}px) {
          display: none;
        }
      }
    `
  }}
`

function centerFirstIndex(index: number) {
  if (index === 0) {
    return 1
  } else if (index === 1) {
    return 0
  }
  return index
}

export const StyledSwiperSlide = styled(SwiperSlide)<{
  $index: number
}>`
  display: flex;
  justify-content: center;
  ${(props) => `order: ${centerFirstIndex(props.$index)}`}
`

export const SwiperArrows = styled.div`
  ${Prev} {
    margin-left: 1rem;
  }
  ${Next} {
    margin-right: 1rem;
  }
`
