import { ApolloError } from '@apollo/client'
import useGoogleApi, { LatLng } from '@hooks/useGoogleApi'
import {
  InfoWindowF,
  GoogleMap as Map,
  MarkerClustererF,
  MarkerF,
} from '@react-google-maps/api'
import { ClustererOptions } from '@react-google-maps/marker-clusterer'
import parse from 'html-react-parser'
import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import { ButtonModifier, ButtonTypes } from '@components/atoms/Button/Button'
import Text from '@components/atoms/Text'
import { TextVariants } from '@components/atoms/Text/Text'

import { parseOptions } from '@helper/parseOptions'
import { PropsWithClassName } from '@helper/PropsWithClassName'

import { Studio } from '@definitions/types/symfonyTypesd'

import {
  DummyMap,
  LoadingText,
  ParsedAddress,
  Root,
  StyledButton,
  StyledInfoWindow,
  StyledLoadingSpinner,
} from './GoogleMap.styles'

interface Props extends PropsWithClassName {
  studios: Studio[]
  setStudio: (studio: Studio) => void
  loading: boolean
  error: ApolloError | undefined
  center?: LatLng
}

const GoogleMap: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { studios, setStudio } = props
  const [userLocation, setUserLocation] = useState<LatLng | null>(null)
  const [studiosOnMap, setStudiosOnMap] = useState<Studio[]>([])

  const [center, setCenter] = useState<LatLng>({ lat: 51.1657, lng: 10.4515 })
  const [activeStudio, setActiveStudio] = useState<Studio | null>(null)

  const { googleApiLoaded } = useGoogleApi()

  const theme: any = useTheme()

  useEffect(() => {
    // We cannot use studios directly, because MarkerClustererF is sometimes buggy
    // more infos: https://github.com/JustFly1984/react-google-maps-api/issues/2849#issuecomment-1645506350
    // Other react google maps packages also have problems with MarkerClusterer...
    setStudiosOnMap(studios)
  }, [studios])

  useEffect(() => {
    if (googleApiLoaded && props.center === undefined) {
      navigator.geolocation?.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          if (!userLocation) {
            setUserLocation({ lat: latitude, lng: longitude })
          }
        },
        (error) => {
          console.error('Error getting user location:', error)
        }
      )
    }
  }, [googleApiLoaded, props.center, userLocation])

  if (!googleApiLoaded) {
    return (
      <Root className={props.className} data-testid={'google-map-root'}>
        <DummyMap>
          <StyledLoadingSpinner />
          <LoadingText variant={TextVariants.lead}>Loading...</LoadingText>
        </DummyMap>
      </Root>
    )
  }

  const handleSelectStudio = (studio: Studio) => {
    setStudio(studio)
    setActiveStudio(null)
  }

  const mapOptions = {
    disableDefaultUI: true,
    clickableIcons: true,
    fullscreenControl: false,
    zoomControl: true, // display -+ button
    styles: [
      {
        featureType: 'all',
        stylers: [
          {
            saturation: -100, // Remove saturation from all elements
            hue: '#ffffff', // Set hue to white to remove any color
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off', // Hide administrative boundaries
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off', // Hide country boundaries
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 10, // Increase lightness to make it look like greyscale
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off', // Hide points of interest
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#cccccc', // Grey color for roads
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off', // Hide transit lines and stations
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#dddddd', // Light grey color for water bodies
          },
        ],
      },
    ],
  }

  const mapClusterOptions: ClustererOptions = {
    styles: [
      {
        textColor:
          theme.key === 'sunpoint'
            ? theme.palette.default.black
            : theme.palette.default.white,
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: '700',
        url: `data:image/svg+xml,%3Csvg width='52' height='52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ccircle cx='26' cy='26' r='14' fill='${encodeURIComponent(
          theme.palette.default.primary
        )}'/%3E%3C/g%3E%3C/svg%3E`,
        height: 52,
        width: 52,
        textSize: 11,
      },
    ],
  }

  return (
    <Root className={props.className} data-testid={'google-map-root'}>
      <Map
        options={mapOptions}
        zoom={6}
        center={props.center ?? (userLocation || center)} // Use user's location if available
        mapTypeId={google.maps.MapTypeId.ROADMAP}
        mapContainerStyle={{ width: '100%', height: '450px' }}
      >
        <MarkerF
          position={{
            lat: props.center?.lat || userLocation?.lat || center.lat,
            lng: props.center?.lng || userLocation?.lng || center.lng,
          }}
          icon={'/img/marker/marker_map.png'}
        ></MarkerF>

        {studiosOnMap.length > 0 && (
          <MarkerClustererF
            options={mapClusterOptions}
            onUnmount={(clusterer) => clusterer.clearMarkers()}
          >
            {(clusterer) => {
              return (
                <Fragment>
                  {studiosOnMap.map((studio) => {
                    if (
                      typeof studio.latitude === 'number' &&
                      typeof studio.longitude === 'number'
                    ) {
                      let marker
                      if (theme.key === 'sunpoint') {
                        marker = '/img/marker/marker_sunpoint.png'
                      }
                      if (theme.key === 'wellmaxx') {
                        marker = '/img/marker/marker_wellmaxx.png'
                      }
                      return (
                        <MarkerF
                          key={studio.id + studio.name}
                          position={{
                            lat: studio.latitude,
                            lng: studio.longitude,
                          }}
                          icon={marker}
                          onClick={() => setActiveStudio(studio)}
                          clusterer={clusterer}
                        >
                          {activeStudio?.id === studio.id && (
                            <InfoWindowF
                              onCloseClick={() => setActiveStudio(studio)}
                            >
                              <StyledInfoWindow>
                                <Text variant={TextVariants.paragraph}>
                                  <b>{studio.title}</b>
                                </Text>
                                <ParsedAddress>
                                  {parse(studio.address, parseOptions)}
                                </ParsedAddress>
                                <StyledButton
                                  disabled={false}
                                  loading={false}
                                  buttonType={ButtonTypes.BUTTON}
                                  modifier={ButtonModifier.SMALL}
                                  onClick={() => handleSelectStudio(studio)}
                                >
                                  Studio wählen
                                </StyledButton>
                              </StyledInfoWindow>
                            </InfoWindowF>
                          )}
                        </MarkerF>
                      )
                    }
                  })}
                </Fragment>
              )
            }}
          </MarkerClustererF>
        )}
      </Map>
    </Root>
  )
}

export { GoogleMap }
